<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner" :style="{ 'background-image': `url(${require('../../../../assets/images/common/top_banner_' + theme_color + '.png')})` }">
        <h1>{{ title }}报告查看</h1>
        <p>
          <span v-if="subtitle">{{ subtitle }}</span>
        </p>
      </div>
      <div class="btn_tab" id="weibo-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a data-href="#sjgs" class="selected cur-p" @click="switchTab(0, true)">事件概述</a>
              <a data-href="#qyfx" class="cur-p" @click="switchTab(1, true)">事件起源分析</a>
              <a data-href="#zsfx" class="cur-p" @click="switchTab(2, true)">事件走势分析</a>
              <a data-href="#tjfx" class="cur-p" @click="switchTab(3, true)">传播途经分析</a>
              <a data-href="#cbzfx" class="cur-p" @click="switchTab(4, true)">传播者分析</a>
              <a data-href="#nrfx" class="cur-p" @click="switchTab(5, true)">传播内容分析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 事件概述 -->
        <a name="sjgs" id="sjgs"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件概述
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">分析时间段内监测到的微博信息传播<br>过程，得出事件的整体情况</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="row.keyword ? 'paragraph lh-30' : 'paragraph'" v-for="(row, index) in data['weibo_analyze']" :key="index">
                <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span v-for="(tag, index) in row.text" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <!-- 事件起源分析 -->
        <a name="qyfx" id="qyfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件起源分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对事件起源微博传播路径、事件起源<br>微博转发时序、事件起源微博传播路<br>径层级百分比、事件起源微博传播路<br>径用户类别百分比综合分析，得出的<br>结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                {{ data['weibo_source'] }}
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件起源微博传播路径图
              <el-tooltip class="item" effect="dark" content="时间段内，事件起源微博传播路径展示" placement="bottom">
                 <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty hide">
                <div class="img"></div>
              </div>
              <div class="h-550" id="weibo_source_spread_path_graph"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件起源微博转发时序图
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，事件起源微博转发量随时间<br>变化趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty hide">
                <div class="img"></div>
              </div>
              <div class="h-250" id="weibo_source_forward_chronological_graph"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">事件起源微博传播路径层级百分比
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，参与事件起源微博转发的各<br>层级占比</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_source_spread_forward_percent'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_source_spread_forward_percent" v-show="data['weibo_source_spread_forward_percent'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">事件起源微博传播路径用户类别百分比
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，事件起源微博的转发量在不同<br>类型用户的分布</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_souce_spread_usertype_percent'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_souce_spread_usertype_percent" v-show="data['weibo_souce_spread_usertype_percent'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <!-- 事件走势分析 -->
        <a name="zsfx" id="zsfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件走势分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对相关信息声量走势、相关信息来源、事件<br>关键走势综合分析，得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div v-for="(row, index) in data['weibo_event_analyze']" :key="index">
                <div v-if="row.hasOwnProperty('text')">
                  <p class="paragraph lh-30">{{ row.text }}</p>
                  <p class="paragraph lh-30" v-for="(item, index) in row.item" :key="index">{{ item }}</p>
                </div>
                <div v-if="row.hasOwnProperty('tags')">
                  <p class="paragraph lh-30">
                    <strong>{{ row.key }}：</strong>
                    <span v-for="(tag, index) in row.tags" :key="index" class="text-tag">{{ tag }}</span>
                  </p>
                </div>
              </div>
              <div v-if="data['weibo_event_analyze'].length < 1"><span>未形成事件</span></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">相关信息声量走势
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，总微博、原发微博和转发微博<br>数量随时间变化的趋势</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_voice_trend'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_voice_trend" v-show="data['weibo_voice_trend'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">相关信息来源
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，原发微博和转发微博数量占<br>总微博数量的比重</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_info_source"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件关键走势
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">根据事件走势，展示关键时间点的传播<br>用户和传播内容</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20" v-if="!data['weibo_key_trend'].isIncubation">
              <div class="echarts_empty" v-if="data['weibo_key_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <!-- 时间轴 -->
              <div class="demo" v-else>
                <div class="main-timeline">
                  <div class="timeline" v-for="(row, index) in data['weibo_key_trend'].data" :key="index">
                    <div class="timeline-content">
                      <div class="circle">
                        <div class="flex">
                          <a :href="row.source_url" target="_blank" class="img">
                            <img :src="row.avatar" width="60" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                            <img src="@/assets/images/analysis/empty_user_avatar.svg" width="60" v-else />
                            <span class="ico v_red" v-if="row.type == '金V'"></span>
                            <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                            <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                            <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                            <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                            <span class="name" :title="row.name">{{ row.name }}</span>
                          </a>
                          <div class="mar-t-10">
                            <h5 class="title"><a :href="row.source_url" target="_blank" :title="row.content">{{ row.content }}</a></h5>
                            <p class="lh-20">
                              <i class="iconfont icon-zhuanfa"></i><span>{{ row.forward }}条</span>
                              <i class="iconfont icon-pinglun"></i><span>{{ row.comment }}条</span>
                              <i class="iconfont icon-dianzan"></i><span>{{ row.like ? row.like : 0 }}条</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="cont">
                        <span class="year">{{ row.date }}</span>
                        <div class="icon"><span></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-lr-20" v-else>
              <div class="w-100 h-400" id="weibo_key_trend"></div>
              <div class="demo2">
                <div class="main-timeline2" v-for="(row, index) in data['weibo_key_trend'].data.timeline" :key="index">
                  <div class="item" v-if="row.type === 'point'">
                    <div class="main-title">
                      <div class="point">
                        <p v-for="(point, index) in row.points" :key="index">
                          <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                          <span>{{ point }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="circle_title">
                            <a :href="row.article.home" target="_blank" class="img">
                              <img :src="row.article.avatar" v-if="row.article.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                              <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                              <span class="ico v_red" v-if="row.article.type == '金V'"></span>
                              <span class="ico v_orange" v-else-if="row.article.type == '黄V'"></span>
                              <span class="ico v_blue" v-else-if="row.article.type == '蓝V'"></span>
                              <span class="ico v_pink" v-else-if="row.article.type == '微博女郎'"></span>
                              <span class="ico v_talent_show" v-else-if="row.article.type == '达人'"></span>
                              <p class="name">{{ row.article.author }}</p>
                            </a>
                            <div class="summary">
                              <h4><a :href="row.article.source_url" target="_blank">{{ row.article.title }}</a></h4>
                              <p class="lh-20">
                                <i class="iconfont icon-zhuanfa"></i><span>{{ row.article.forward }}条</span>
                                <i class="iconfont icon-pinglun"></i><span>{{ row.article.comment }}条</span>
                                <i class="iconfont icon-dianzan"></i><span>{{ row.article.like }}条</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="cont">
                          <span class="year">{{ row.date }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item item_title" v-else>
                    <div class="main-title">
                      <h3>{{ row.state }}</h3>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content bd_n">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(article, index) in row.articles" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-if="index == 1">2</span>
                                <span class="num bg-8ea" v-if="index == 2">3</span>
                                <a class="mod-list-title2">{{ article.title }}</a>
                                <a class="site"><span>|</span>{{ article.media }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 传播途径分析 -->
        <a name="tjfx" id="tjfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">传播途径分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对全部信息传播路径图、关键引爆者<br>综合分析，得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                {{ data['weibo_spread_path_analyse'].title }}
              </p>
              <p class="paragraph lh-30" v-for="(row, index) in data['weibo_spread_path_analyse'].list" :key="index">
                {{ index + 1 + "." + row }}
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">全部信息传播路径图
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">事件在微博的传播路径，通过关键<br>节点可以找出关键的传播用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['weibo_spread_path_graph'].nodes.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="weibo_spread_path_graph" v-show="data['weibo_spread_path_graph'].nodes.length >= 1"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">关键引爆点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，引起微博二次转发最多<br>的10位博主</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <div class="echarts_empty hide">
                <div class="img"></div>
              </div>
              <div class="mwbcon2">
                <div class="ybdChart">
                  <ul id="keyUserTop">
                    <li :class="boomPointClassName(index)" v-for="(row, index) in data['weibo_key_point']" :key="index">
                      <div class="tx ico_red" v-if="row.type == '金V'">
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                      <div class="tx ico_orange" v-else-if="row.type == '黄V'">
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                      <div class="tx ico_blue" v-else-if="row.type == '蓝V'">
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                      <div class="tx ico_pink" v-else-if="row.type == '微博女郎'">
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                      <div class="tx ico_talent_show" v-else-if="row.type == '达人'">
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                      <div class="tx" v-else>
                        <img :src="row.avatar" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                        <img src="@/assets/images/analysis/empty_user_avatar.svg" v-else />
                      </div>
                       <div class="con" v-if="row.source_url">
                        <p> <a href="javascript:;" :title="row.name">{{ row.name }}</a> </p>
                        <p>点赞：<em>{{ row.like }}</em></p>
                        <p>二次转发：<em>{{ row.forward }}</em></p>
                       </div>
                       <div class="summary" v-if="row.source_url">
                         <a :href="row.source_url" target="_blank" :title="row.content"><p>{{ row.content }}</p></a>
                       </div>
                        <p class="zfsj" v-if="row.source_url">
                            <span>转发时间：</span>{{ row.date }}
                        </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 传播者分析 -->
        <a name="cbzfx" id="cbzfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">传播者分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对所有相关信息微博传播用户类别<br>百分比、所有相关信息微博传播者<br>传播路径层级分布、所有相关原文<br>信息微博传播用户类别百分比、所<br>有相关原文信息微博传播者传播路<br>径层级分布综合分析，得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30" v-for="(row,index) in data['weibo_spread_user']" :key="index">
                <strong v-if="row.keyword">{{row.keyword}}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span v-for="(tag, index) in row.text" :key="index" class="text-tag" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">所有相关信息微博传播用户类别百分比
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，所有微博在不同类别用户<br>的数量分布</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_spread_usertype_percent'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_spread_usertype_percent" v-show="data['weibo_spread_usertype_percent'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">所有相关信息微博传播者传播路径层级分布
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，参与相关微博转发的各<br>层级占比</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_spread_user_level'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_spread_user_level" v-show="data['weibo_spread_user_level'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">所有相关原文信息微博传播用户类别百分比
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，原发微博在不同类别用户<br>的数量分布</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_source_spread_usertype_percent'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_source_spread_usertype_percent" v-show="data['weibo_source_spread_usertype_percent'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">所有相关转发信息微博传播用户类别百分比
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，转发微博在不同类别用户<br>的数量分布</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['weibo_forward_spread_usertype_percent'].data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="weibo_forward_spread_usertype_percent" v-show="data['weibo_forward_spread_usertype_percent'].data.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">关键原文传播者
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">事件传播中，原发微博数量最多的<br>10个用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="w-100 ov-h">
                <ul class="user_list">
                  <li v-for="(row, index) in data['weibo_source_spread_user']">
                    <div class="flex">
                      <div class="tx">
                        <img :src="row.avatar" class="img">
                        <span class="num top1" v-if="index == 0"></span>
                        <span class="num top2" v-if="index == 1"></span>
                        <span class="num top3" v-if="index == 2"></span>
                        <span class="ico v_red" v-if="row.type == '金V'"></span>
                        <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                        <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                        <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                        <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                      </div>
                      <div class="con">
                        <p> <a :href="row.home" target="_blank" :title="row.name">{{ row.name }}</a> </p>
                        <p>原发：<em>{{ row.source_count }}</em></p>
                        <p>转发：<em>{{ row.forward_count }}</em></p>
                        <p>总覆盖：<em>{{ row.forward_count + row.source_count }}</em></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">关键转发传播者
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">事件传播中，转发相关微博数量最多的<br>10个用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="w-100 ov-h">
                <ul class="user_list">
                    <li v-for="(row, index) in data['weibo_forward_spread_user']">
                        <div class="flex">
                            <div class="tx">
                                <img :src="row.avatar" class="img">
                                <span class="num top1" v-if="index == 0"></span>
                                <span class="num top2" v-if="index == 1"></span>
                                <span class="num top3" v-if="index == 2"></span>
                                <span class="ico v_red" v-if="row.type == '金V'"></span>
                                <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                                <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                                <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                                <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                            </div>
                            <div class="con">
                                <p> <a :href="row.home" target="_blank">{{ row.name }}</a> </p>
                                <p>原发：<em>{{ row.forward_count }}</em></p>
                                <p>转发：<em>{{ row.source_count }}</em></p>
                                <p>总覆盖：<em>{{ row.forward_count + row.source_count }}</em></p>
                            </div>
                        </div>
                    </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 传播内容分析 -->
        <a name="nrfx" id="nrfx"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">传播内容分析
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对主要传播内容、主要传播情感、事件<br>传播内容走势、热门传播URL链接综合<br>分析，得出的结论</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                {{ data['weibo_spread_content_analysis'] }}
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card zycbnr echarts-box">
                <h3 class="mod-title">主要传播内容
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">对时间段内的事件内容进行分词及重要<br>关键词词频统计，展示词频最高的50个<br>重要关键词</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="w-100 h-100 ov-h flex">
                    <div class="mod-list" v-show="data['weibo_spread_content'].length >= 1">
                      <ul>
                        <li v-for="(row, index) in data['weibo_spread_content']" v-if="index <= 9">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :title="row.keyword">{{ row.keyword }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="gjcy mod-echarts-right" id="weibo_spread_content" v-show="data['weibo_spread_content'].length >= 1"></div>
                    <div class="echarts_empty" v-show="data['weibo_spread_content'].length < 1">
                      <div class="img"></div>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card ov-v">
                <h3 class="mod-title">主要传播情感
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">对时间段内的事件内容进行情感分析，<br>提取敏感信息中的负面关键词，非敏感<br>信息中的正面关键词，并进行词频统计，<br>分别展示词频最高的10个情感关键词</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20 pos-r">
                  <div class="echarts_empty" v-show="data['weibo_spread_emotion'].length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-380" id="weibo_spread_emotion" v-show="data['weibo_spread_emotion'].length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">事件传播内容走势
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，事件传播关键词随时间变化<br>的趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['weibo_spread_content_trend'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="h-450" id="weibo_spread_content_trend" v-show="data['weibo_spread_content_trend'].data.length >= 1"></div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card rmcb_urllj">
            <h3 class="mod-title">热门传播URL链接
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对微博中包含的URL链接进行数量统计，<br>展示数量最多的10条</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <el-row :gutter="20" class="el-row-20">
                <el-col :span="12">
                  <div class="mod-list">
                    <ul>
                      <li>
                        <h3><span class="num-tit">序号</span><span class="mod-list-title2">URL链接</span><span class="tag">引用微博数量</span></h3>
                      </li>
                      <li v-for="(row, index) in data['weibo_spread_url']" :key="index" v-if="index <= 4">
                        <span class="num bg-555" v-if="index == 0">1</span>
                        <span class="num bg-689" v-if="index == 1">2</span>
                        <span class="num bg-8ea" v-if="index == 2">3</span>
                        <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                        <a class="mod-list-title2" :href="row.url" target="_blank">{{ row.url }}</a>
                        <span class="tag">{{ row.total }}条</span>
                      </li>
                    </ul>
                  </div>
                </el-col>
                <el-col :span="12" v-if="data['weibo_spread_url'].length > 5">
                  <div class="mod-list">
                    <ul>
                      <li>
                        <h3><span class="num-tit">序号</span><span class="mod-list-title2">URL链接</span><span class="tag">引用微博数量</span></h3>
                      </li>
                      <li v-for="(row, index) in data['weibo_spread_url']" :key="index" v-if="index >= 5">
                          <span class="num bg_2a6">{{ index + 1 }}</span>
                          <a class="mod-list-title2" :href="row.url" target="_blank">{{ row.url }}</a>
                          <span class="redu">{{ row.total }}条</span>
                      </li>
                    </ul>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
  import { findPointImg, globalCreateChart } from "@/utils/helpers.js"
  import { detail } from "@/api/analysis";
  const d3 = require("d3");
  const $ = window.$;
  const _ = window._;
  export default {
    name: "analysis-propagation-weibo",
    data() {
      return {
        data: {},
        loading: true,
        title: "",
        created: ""
      }
    },
    mounted() {
      this.loadData()
      this.$nextTick(() => {
        let _this = this
        window.onscroll = function () {
          let height = document.documentElement.scrollTop + 75
          if (height > 200) {
            $("#weibo-tab").addClass("scroll-tab")
            $("#weibo-tab").addClass("scroll")
          } else {
            $("#weibo-tab").removeClass("scroll")
            $("#weibo-tab").removeClass("scroll-tab")
          }
          let b = window.document.getElementById("qyfx").offsetTop
          let c = window.document.getElementById("zsfx").offsetTop
          let d = window.document.getElementById("tjfx").offsetTop
          let e = window.document.getElementById("cbzfx").offsetTop
          let f = window.document.getElementById("nrfx").offsetTop
          if (height < b) {
            _this.switchTab(0)
          } else if (height >= b && height < c) {
            _this.switchTab(1)
          } else if (height >= c && height < d) {
            _this.switchTab(2)
          } else if (height >= d && height < e) {
            _this.switchTab(3)
          } else if (height >= e && height < f) {
            _this.switchTab(4)
          } else {
            _this.switchTab(5)
          }
        }
      })
    },
    methods: {
      isArray(data) {
        return _.isArray(data)
      },
      switchTab(index, scrollTo = false) {
        if (scrollTo) {
          var h = 0
          switch (index) {
            case 0:
              document.documentElement.scrollTop = h
              break;
            case 1:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("qyfx").offsetTop - h
              break;
            case 2:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("zsfx").offsetTop - h
              break;
            case 3:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("tjfx").offsetTop - h
              break;
            case 4:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("cbzfx").offsetTop - h
              break;
            case 5:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("nrfx").offsetTop - h
              break;
          }
        }
        $(".flex-container a").removeClass("selected")
        $(".flex-container a:eq("+ index +")").addClass("selected")
      },
      loadData() {
        detail({id: this.$route.query.id}).then(res => {
          if (res.data.state) {
            this.data = JSON.parse(res.data.data.result)
            const { title, updated, subtitle, theme_color } = res.data.data
            this.title = title;
            this.subtitle = subtitle;
            this.theme_color = theme_color;
            this.created = updated;
            this.loading = false;
            this.handleData()
            this.$nextTick(() => {
              this.createChart()
            })
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(res => {
          this.$message.error("加载数据失败，服务失败")
        })
      },
      handleData() {
        let len = 10 - this.data['weibo_key_point'].length
        for (let i = 0; i < len; i++) {
          this.data['weibo_key_point'].push({
            avatar: "/images/empty_user_avatar.svg",
            source_url: null
          })
        }
      },
      boomPointClassName(index) {
        let num = index <= 4 ? index + 1 : index - 4
        return index <= 4 ? "ybd_r ybd_r_" + num.toString() : "ybd_l ybd_l_" + num.toString()
      },
      createChart() {
        this.treeChart(this.data['weibo_source_spread_path_graph'], 'weibo_source_spread_path_graph')
        let loopKeys = ["weibo_source_spread_forward_percent", "weibo_souce_spread_usertype_percent", "weibo_info_source", "weibo_spread_usertype_percent", "weibo_spread_user_level", "weibo_source_spread_usertype_percent", "weibo_forward_spread_usertype_percent"]
        for (let i = 0; i < loopKeys.length; i++) {
          this.loopChart(this.data[loopKeys[i]], loopKeys[i])
        }
        this.trendChart(this.data['weibo_source_forward_chronological_graph'], 'weibo_source_forward_chronological_graph')
        this.trendChart(this.data['weibo_voice_trend'], 'weibo_voice_trend')
        this.scatterChart(this.data['weibo_spread_path_graph'], 'weibo_spread_path_graph')
        this.wordCloudChart(this.data['weibo_spread_content'], 'weibo_spread_content')
        this.wordPopChart(this.data['weibo_spread_emotion'], 'weibo_spread_emotion')
        this.wordHeatChart(this.data['weibo_spread_content_trend'], 'weibo_spread_content_trend')
        if (this.data['weibo_key_trend'].isIncubation) {
          this.eventTrend('weibo_key_trend')
        }
      },
      eventTrend(id) {
        let data = this.data['weibo_key_trend'].data.trend
        let keys = []
        let seriesData = []
        data.map((item) => {
          keys.push(item.date)
          if (item.points) {
            seriesData.push({
              value: item.count,
              symbol: 'image://' + findPointImg(item.points[0]),
              name: item.points.join(",")
            })
          } else {
            seriesData.push({
              value: item.count,
              name: ""
            })
          }
        })
        let option = {
          tooltip : {
            trigger: 'axis'
          },
          calculable : true,
          xAxis : [
            {
              type : 'category',
              boundaryGap : false,
              data : keys
            }
          ],
          yAxis : [
            {
              type : 'value'
            }
          ],
          series : [
            {
              type:'line',
              stack: '总量',
              symbol: 'none',
              symbolSize:20,
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    return params.data.name
                  }
                },
              },
              data: seriesData
            }
          ]
        };
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
        window.addEventListener("resize", () => { chart.resize();})
      },
      treeChart(data, id) {
        if (data.children.length > 55) {
          let sym = parseInt(data.children.length / 55)
          data.children = data.children.map((item, index) => {
            item.label = {
              show: (index % sym == 0) ? true : false
            }
            return item
          })
        }
        let option = {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          series: [
            {
              type: 'tree',
              data: [data],
              top: '18%',
              bottom: '10%',
              symbol: 'emptyCircle',
              symbolSize: 7,
              initialTreeDepth: 3,
              animationDurationUpdate: 750
            }
          ]
        }
        if (data.children.length >= 1) option.series[0].layout = 'radial'
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      trendChart(data, id) {
        let option = {
          tooltip : {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            top:"5px",
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            itemGap:20
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '40px',
            bottom: '20px',
            width:"90%",
            containLabel: true
          },
          xAxis : {
            type : 'category',
            boundaryGap : false,
            data: data.keys
          },
          yAxis: {type : 'value'},
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: ["#83d944","#f0a142","#3178d2 ","#568efc ","#8abaf8","#67a5ff","#11abf3","#fbe44d","#55d9ff","#74ccb1","#ee8131"],
          series : []
        }
        if (id == "weibo_voice_trend") {
          option.legend.data = data.type;
          _(data.type).forEach((item, index) => {
            option.series.push({
              name: item,
              type: 'line',
              smooth: true,
              areaStyle: {normal: {}},
              data: data.data[index]
            })
          })
        } else {
          option.series.push({
            name: "数据走势",
            type: 'line',
            smooth: true,
            color: '#9dddcf',
            areaStyle: {normal: {}},
            data: data.data
          })
        }
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      loopChart(data, id) {
        var total = 0
        var legend = []
        var rateMap = []
        var colors = ["#2daff7", "#ffa137", "#ed3914", "#0078d5", "#bc1719", "#fb448c"]
        let seriesData = []
        if (["weibo_source_spread_forward_percent", "weibo_spread_user_level"].indexOf(id) != -1) {
          // 路径层级图
          legend = data.type;
          _(data.data).forEach((num, index) => {
            total += num
          })
          _(data.data).forEach((num, index) => {
            seriesData.push({
              name: legend[index],
              value: num
            })
            rateMap.push((num / total * 100).toFixed(2))
          })
        } else if (id == "weibo_info_source") {
          // 信息来源
          legend = ["转发", "原发"]
          total = parseInt(data.forward) + parseInt(data.original)
          rateMap = [(parseInt(data.forward) / total * 100).toFixed(2), (parseInt(data.original) / total * 100).toFixed(2)]
          seriesData = [{name: "转发", value: data.forward}, {name: "原发", value: data.original}]
        } else {
          // 用户类别
          legend = data.type;
          _(data.data).forEach((num, index) => {
            total += num
          })
          _(data.data).forEach((num, index) => {
            seriesData.push({
              name: legend[index],
              value: num
            })
            rateMap.push((num / total * 100).toFixed(2))
          })
        }
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c}({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: legend,
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            formatter: function (name) {
              let index = legend.indexOf(name);
              return name + " " + rateMap[index] + '%'
            }
          },
          color: colors,
          series: [
            {
              name: "媒体信息来源类型分布",
              type:'pie',
              center:['50%', '50%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '16',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: seriesData
            }
          ]
        }
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      scatterChart(data, id) {
        data.links = data.links.map(function (item, index) {
          item["id"] = index.toString()
          item["lineStyle"] = {normal: {}}
          item["name"] = null
          return item
        })
        data.nodes = data.nodes.map(function (item) {
          item["draggable"] = false
          item["itemStyle"] = null
          item["symbolSize"] = (item.symbolSize + 5) > 30 ? 30 : item.symbolSize + 5
          item["value"] = item.forward
          return item
        })
        let option = {
          tooltip: {},
          animation: false,
          scaleLimit:{min:0,max:0.5},
          series : [
            {
              name: '全部信息传播路径图',
              type: 'graph',
              layout: 'force',
              data: data.nodes,
              links: data.links,
              roam: true,
              label: {
                normal: {
                  position: 'right'
                }
              },
              force: {
                repulsion: 100
              }
            }
          ]
        };
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      wordCloudChart(data, id) {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          series: [{
            name: '信息数',
            type: 'wordCloud',
            shape: 'circle',
            top: '10%',
            width: '100%',
            height: '85%',
            right: null,
            bottom: null,
            sizeRange: [12, 30],
            rotationRange: [-90, 90],
            rotationStep: 45,
            gridSize: 8,
            drawOutOfBound: false,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                //fontWeight: 'bold',
                color: function (params) {
                  // Random color
                  let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                  let index = Math.floor((Math.random()*color.length))
                  return color[index]
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            data: []
          }]
        }
        let chartData = [];
        _(data).forEach((item, index) => {
          chartData.push({name: item.keyword, value: item.total})
        })
        option.series[0].data = chartData
        let element = window.document.getElementById(id)
        var chart = globalCreateChart(element, option)
      },
      wordPopChart(data, id) {
        let maxr = data.length >= 1 ? data.sort((a, b) => { return b.total - a.total })[0].total : 0
        function Bubble(option){
          var _defaultOption = {
            width: 300,
            height: 300,
            padding:1.5,
            data:'',
            conEle:''
          };
          option = $.extend(true, _defaultOption,option);
          this.width  = option.width;
          this.height = option.height;
          this.padding= option.padding;
          this.data   = option.data;//数据url或对象,必填
          this.conEle = option.conEle;//svg容器(node或者选择器)，必填
          this.mouseenter = function(d,node){}
          this.mouseleave = function(d,node){}
          this.click = function (d) {}
        }
        Bubble.prototype.init = function(){
          var that = this,
            //1.设置颜色
            color = d3.scale.category20c(),
            //2.布局
            bubble = d3.layout.pack()
              .sort(null)
              .size([that.width,that.height])
              .radius((d) => {
                let val = d / maxr * 50
                return val < 20 ? 20 : val
              })
              .padding(that.padding),
            //3.添加svg元素
            svg = d3.select(that.conEle).append("svg")
              .attr("width", that.width)
              .attr("font-size", '12')
              .attr("height", that.height);
          //4.数据请求及图形绘制
          if(typeStr(that.data)=='[object string]'){
            d3.json(that.data,function(error,data){
              if(error) throw error;
              //1.对数据进行处理
              data = dataHandle(data);
              render(svg,bubble,that,data);
            })
          }else{
            render(svg,bubble,that,dataHandle(that.data));
          }
        }
        function typeStr(obj){
          return Object.prototype.toString.call(obj).toLowerCase();
        }
        //Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root){
          var classes = [];                                                                                        //存储结果的数组
          /*
           * 自定义递归函数
           * 第二个参数指传入的json对象
           */
          function recurse(name, node) {
            if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            {
              node.children.forEach(function(child) {                                                          //将孩子结点中的每条数据
                recurse(node.name, child);
              })
            }
            else {
              //如果自身是孩子结点的，将内容压入数组
              classes.push({ name: node.name, value: node.size,props:node.props})
            };
          }
          recurse(null, root);
          return {children: classes};
        }
        function render(view,layout,context,data,cb){
          var node = view.selectAll(".node")
            //绑定数据（配置结点）
              .data(layout.nodes(classes(data))
                .filter(function(d) {
                  //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                  return !d.children;
                }))
              .enter().append("g")
              .attr("class", "node")
              .attr("transform", function(d) {
                //设定g移动
                return "translate(" + d.x + "," + d.y + ")";
              }),
            usingNodes = node.filter(function(d){
              return d.props.using;
            }),
            time = +new Date(),
            duration = 1000,
            strokeWidth =0;
          node.append("circle")
            .attr("r", function(d) {
              //设置圆的半径
              return d.r;
            })
            .style("fill", function(d) {
              //气泡颜色
              return d.props.color;
            })
            .style("fill-opacity",1);
          node.append("text")
            .attr("dy", ".3em")
            //设置文本对齐
            .style("text-anchor", "middle")
            .style("font-size",'12px')
            .style("fill", function (d) {
              //字体颜色
              return "#fff"
            })
            //根据半径的大小来截取对应长度字符串(很重要)
            .text(function(d) {
              return d.name.substring(0, d.r / 8);
            });
          function animate(){
            var nowTime = +new Date();
            if((nowTime-duration) > time) {
              time = nowTime;
              strokeWidth = 0;
            }
            strokeWidth += 0.6;
            //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
            usingNodes.select("circle")
              .style("stroke-width",strokeWidth+'px')
              .style("stroke-opacity",'0.3')
              .style("stroke",function(d){
                return d.props.color;
              });
            requestAnimationFrame(animate);
          }
          animate();
          node.on('mouseenter',function(d){
            var node = this;
            context.mouseenter(d,node);
          })
          node.on('mouseleave',function(d){
            var node = this;
            context.mouseleave(d,node);
          })
          node.on('click', function (d) {
            var node = this;
            context.click(d)
          })
        }
        //定义数据处理方法
        function dataHandle(data){
          var result = {
            name:"flare",
            children:[]
          }
          data.forEach(function(ele){
            result.children.push({
              name:ele.name,
              size:ele.value,
              props:ele.props
            });
          });
          return result;
        }
        function createInfoTip(d){
          var html = '<div class="node-info fz-12"><ul>';
          html += '<li class="info-title"><span>'+d.name+'</span></li>';
          if (d.props.color == "#66a5ff") {
            html += '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数'+
              '</span><span class="info-content-text">'+d.value+'</span></li>';
          } else {
            html += '<li class="info-content"><i class="bg-abnormal"></i><span class="info-content-label">信息数'+
              '</span><span class="info-content-text">'+d.value+'</span></li>';
          }
          html += '</ul></div>';
          return html;
        }
        // 清除旧数据
        window.jQuery("#"+id).children().remove()
        d3.select('#'+id)
          .selectAll('*')
          .remove();
        var chartData = [];
        _(data).forEach((item, i) => {
          chartData.push({
            name: item.keyword,
            value: item.total,
            props: {
              abnormal: false,
              color: item.emotion == "positive" ? "#66a5ff" : "#fa3851",
              using: false
            }
          })
        })
        let element = window.document.getElementById(id)
        if (!element) return false // 切换时直接break
        var option = {
          data: chartData,
          conEle:'#'+id,
          width: 530,
          height: 380,
          padding:2
        }
        var bubble = new Bubble(option)
        bubble.click = function (d) {
          // TODO:: 列表点击事件
        }
        bubble.mouseenter = function(d,node){
          var $con = $("#"+id);
          var rectBox = $con[0].getBoundingClientRect();
          d3.select(node).style("cursor","pointer");
          $con.append(createInfoTip(d));
          $(".node-info").css({
            left: d3.event.x+20-rectBox.left,
            top: d3.event.y+20-rectBox.top
          }).show();
        }
        bubble.mouseleave = function(d){
          $(".node-info").remove();
        }
        bubble.init()
      },
      wordHeatChart(data, id) {
        var maxTotal = 0
        data.data.map((pdata, index) => {
          pdata.map((sdata, sindex) => {
            if (sdata.count > maxTotal) maxTotal = sdata.count
          })
        })
        let option = {
          tooltip: {
            position: 'top',
            formatter: function (params) {
              return params.seriesName + "：" + params.data[2]
            }
          },
          animation: false,
          grid: {
            bottom: 10,
            left: 130
          },
          xAxis: {
            type: 'category',
            data: [],
            splitArea: {
              show: true
            },
            show: false
          },
          yAxis: {
            type: 'category',
            data: data.keys.map((item, index) => {
              return {value: [item, data.desc[index] ? data.desc[index] : '']}
            }),
            splitArea: {
              show: true
            },
            axisLabel: {
              formatter: function(arg) {
                return '{a|'+arg[0]+'} {b|'+arg[1]+'}'
              },
              rich: {
                b: {
                  backgroundColor: '#e3eefd',
                  padding: 3
                }
              }
            }
          },
          visualMap: {
            min: 1,
            max: maxTotal,
            calculable: true,
            orient: 'horizontal',
            show: false,
            inRange: {
              color: ['#ffbeb2','#EE000F']
            }
          },
          series: []
        }
        let seriesData = [];
        _(data.data).forEach((item, index) => {
          _(item).forEach((sub_item, sub_index) => {
            let temp = {
              name: sub_item.media,
              type: 'heatmap',
              data: [[sub_index, index, sub_item.count]],
              label: {
                normal: {
                  show: true,
                  formatter: sub_item.media
                }
              }
            }
            seriesData.push(temp)
          })
        })
        option.series = seriesData
        let element = window.document.getElementById(id)
        var chart = globalCreateChart(element, option)
      },
      findPointImg(name) {
        return findPointImg(name)
      }
    }
  }
</script>
<style scoped>
.scroll-tab{position: fixed;z-index: 3000;left:auto;right:auto;top:200px;margin: auto;}
.scroll{top: 0;}
.scroll .fxbg{box-shadow:0px 3px 13px 0px rgba(194,194,194,0.2);padding: 10px 0;}
.flex-container{justify-content: space-between;align-content: space-between;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;width:1160px;float:none;}
.btn_tab a{height:40px;line-height: 40px;text-align: center;background:rgba(237,237,237,.6);color:rgba(0,0,0,.6);border-radius:5px;display: block;padding:0 50px;border: none;font-size:14px;}
.btn_tab a.selected{color: #fff;background:rgba(42,103,179,1);}
.echarts_empty{height: 250px;}
/*时间轴*/
.demo{width:1116px;margin:20px auto;padding:45px 0 0;}
.main-timeline{ position: relative; }
.main-timeline:before,.main-timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline:before{content: "";width: 3px;height:calc(100% - 50px);background:#bebebe;position: absolute;top: 0;left: 50%;}
.main-timeline .timeline{width: 50%;float: left;position: relative;z-index: 1;}
.main-timeline .timeline:before,.main-timeline .timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline .timeline-content{text-align: center;position: relative;transition: all 0.3s ease 0s;}
.main-timeline .timeline-content:before{content: "";width: 100%;height:3px;background:#bebebe;position: absolute;left: 0;z-index: -1;top:7px;}
.main-timeline .circle{width:450px;height:95px;background:rgb(239,244,254);border-radius: 8px;float: left;position: relative;color: #000;margin-top: -35px;text-align: left;padding-right:10px;}
.demo .title{display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:3;}
.main-timeline .cont{display: table;padding:0 5px;position: relative;}
.main-timeline .year{display: block;margin:0 0 50px 0;border-radius: 7px;font-size:12px;color: #fff;width:77px;height:30px;background-color: #2a67b3;border-radius: 4.5px;margin-top: -39px;line-height: 15px;padding: 5px;text-align: left;text-align: center;}
.main-timeline .icon{width:16px;height:16px;border-radius: 50%;background: #fff;border:3px solid #2a67b3;position: absolute;top:0;right: -12px;display: inline-block;}
.main-timeline .timeline:first-child .timeline-content:before{top:7px;}
.main-timeline .timeline:nth-child(2n) .timeline-content,.main-timeline .timeline:nth-child(2n) .circle{float: right;}
.main-timeline .timeline:nth-child(even){margin-top:60px;}.flex{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;}
.main-timeline .timeline:nth-child(even) .timeline-content:before{top:3px;}
.main-timeline .timeline:nth-child(even) .year{margin-top:-40px;}
.main-timeline .timeline:nth-child(even) .icon{left:-10px;right: 0;}
.main-timeline .mod-list{padding:8px 10px;}
.main-timeline .img{width: 60px;height: 60px;margin:5px 10px;position: relative;}
.main-timeline .img img{border-radius: 50%;width: 60px;height: 60px;}
.main-timeline .img .ico{width:16px;height:16px;z-index: 10;position: absolute;bottom:2px;right:2px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.main-timeline .img .name{display: block;height: 24px;line-height: 24px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;font-weight:600;color:rgba(42,103,179);}
.main-timeline .mod-list-title2{font-size: 12px;line-height: 24px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.circle_title .iconfont{float:left;}
.main-timeline .lh-20{line-height: 20px;font-size: 12px;margin-top:5px;}
.main-timeline .lh-20 .iconfont{float: left;margin-right:5px;}
.main-timeline .lh-20 span{padding:0 5px;float: left;color: rgba(0,0,0,.7)}
/*关键引爆点*/
.mwbcon2{margin:0 auto;width:1000px;}
.ybdChart {width:860px;height:516px;background-position:center;background-repeat:no-repeat;position:relative;margin:0 auto;background-image: url(../../../../assets/images/analysis/ybd_bg.png);}
.ybdChart > ul > li {position:absolute;width:450px;}
.ybdChart > ul > li.ybd_r {text-align:left;}
.ybdChart > ul > li.ybd_r_1 {top:20px;right:-100px;}
.ybdChart > ul > li .tx,.ybdChart > ul > li .tx img{width:54px;height: 54px;}
.ybdChart > ul > li.ybd_r .tx,.ybdChart > ul > li.ybd_r .con,.ybdChart > ul > li.ybd_r .summary{float:left;margin-right:10px;display:inline;font-size: 12px;}
.ybdChart > ul > li.ybd_l .con,.ybdChart > ul > li.ybd_r .con{width:110px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;
}
.ybdChart > ul > li .tx img {width:54px;border-radius:50%;cursor:pointer;}
.ybdChart > ul > li .tx p{line-height:18px;color: #000;}
.ybdChart > ul > li .con p:first-child a {color:#333333;font-weight:600;}
.ybdChart > ul > li .con p em {color:#008afa;}
.ybdChart > ul > li .con p:nth-child(3) em {color:#d4403c;}
.ybdChart .summary{width:186px;font-size:11px;font-weight:400;color:rgba(0,0,0,1);opacity:0.9;background:#edf4fe;border-radius:7px;line-height:22px !important;}
.ybdChart .summary p{margin:8px 13px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;line-height: 24px;height: 48px;}
.ybdChart .zfsj{clear: both;font-size: 12px;}
.ybdChart > ul > li.ybd_r_2 {top:125px;right:-185px;}
div.ico_red,div.ico_blue,div.ico_orange,div.ico_pink,div.ico_talent_show,div.ico_yellow{position:relative;}
div.ico_red:after,div.ico_blue:after,div.ico_orange:after,div.ico_pink:after,div.ico_talent_show:after,div.ico_yellow:after{content:"";text-align:center;width:16px;height:16px;position:absolute;bottom:2px;right:2px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.ybdChart > ul > li.ybd_r_3 {top:235px;right:-205px;}
.ybdChart > ul > li.ybd_r_4 {top:335px;right:-175px;}
.ybdChart > ul > li.ybd_r_5 {top:435px;right:-80px;}
.ybdChart > ul > li.ybd_l {text-align:right;}
.ybdChart > ul > li.ybd_l .tx,.ybdChart > ul > li.ybd_l .con,.ybdChart > ul > li.ybd_l .summary{float:right;margin-right:10px;display:inline;font-size: 12px;}
.ybdChart > ul > li.ybd_l_5 {top:435px;left:-80px;}
.ybdChart > ul > li.ybd_l_4 {top:335px;left:-175px;}
.ybdChart > ul > li.ybd_l_3 {top:235px;left:-205px;}
.ybdChart > ul > li.ybd_l_2 {top:125px;left:-185px;}
.ybdChart > ul > li.ybd_l_1 {top:20px;left:-100px;}
/*关键原文传播者*/
.flex{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;}
.user_list li{float: left;border-right: 1px solid rgba(191,191,191,.4);margin:20px 11px 0;font-size: 12px;}
.user_list li:nth-child(5n){border: none;}
.user_list li .tx {width:80px;position: relative;margin-top:5px;}
.user_list .img{width: 80px;height: 80px;border-radius: 50%;cursor: pointer;}
.user_list .tx .ico{width:16px;height:16px;z-index: 10;position: absolute;bottom:5px;right:5px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.user_list .tx .num{width:55px;height:52px;z-index: 10;position: absolute;top:2px;left:2px; background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;text-indent:-99999px;}
.user_list .tx .top1{background-image: url(../../../../assets/images/analysis/top1.png);}
.user_list .tx .top2{background-image: url(../../../../assets/images/analysis/top2.png);}
.user_list .tx .top3{background-image: url(../../../../assets/images/analysis/top3.png);}
.user_list .con{width:100px;padding: 0 10px;font-size: 12px;line-height: 20px;}
.user_list .con p{height: 20px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.user_list .con a{font-weight:600;color: #3F90F7;}
.user_list .con em{color: #000;}
/*主要传播内容*/
.zycbnr .mod-list{width: 120px;margin-top:16px;float: left;}
/* .gjcy{float: left;margin-top:16px;} */
/*热门传播URL链接*/
.rmcb_urllj .mod-list-title2{width:72%;}
.rmcb_urllj .tag{width:18%;}
/*事件关键时间点*/
.demo2{width:1116px;margin:30px auto 0;padding:0;position: relative;}
.demo2:before{content: "";width: 3px;background:#bebebe;position: absolute;top:40px;left:10%;bottom:68px;height: calc(100% - 108px);}
.main-timeline2{ position: relative;padding-bottom:20px;}
.main-timeline2 .timeline{width:90%;float: right;position: relative;z-index: 1;padding-bottom: 40px;}
.main-timeline2 .timeline:before,.main-timeline2 .timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-title{width:10%;float: left;position: relative;z-index: 1;margin-top:30px;}
.main-title .point{height:70px;overflow: hidden;}
.main-title p{line-height:24px;overflow: hidden;}
.main-title p span{font-size: 14px;}
.main-title p .img{margin-right:8px;vertical-align: middle;}
.main-title h3{width:36px;height:123px;background:linear-gradient(0deg,rgba(245,245,245,1) 0%,rgba(199,199,199,1) 50%,rgba(255,255,255,1) 100%);border-radius:18px;font-size: 20px;color:rgba(0,103,177,1);line-height:30px;text-align: center;padding-top:13px;margin-left:25px;}
.main-timeline2 .timeline:last-child,.main-timeline2 .main-title:nth-last-child(2){padding-bottom: 0;}
.main-timeline2 .timeline-content{text-align: center;position: relative;transition: all 0.3s ease 0s;}
.main-timeline2 .timeline-content:before{content: "";width: 100%;height:3px;background:#bebebe;position: absolute;left: 0;z-index: -1;top:45px;}
.main-timeline2 .circle{width:830px;float: right;position: relative;color: #000;text-align: left;overflow: hidden;}
.main-timeline2 .circle_title,.main-timeline2 .mod-list{background:rgb(239,244,254);border-radius: 8px;}
.main-timeline2 .circle_title{line-height:25px;padding:8px 15px;overflow: hidden;}
.circle_title .img{width:40px;height:40px;margin:0 10px 0 0;position: relative;float: left;display: inline;}
.circle_title .img img{border-radius: 50%;width:40px;height:40px;}
.circle_title .img .ico{width:16px;height:16px;z-index: 10;position: absolute;bottom:0;right:0;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.circle_title .img .name{display: block;height: 24px;line-height: 24px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;font-weight:600;color:rgba(42,103,179);font-size: 14px;}
.circle_title .summary{float: left;overflow: hidden;width:750px;}
.main-timeline2 .circle_title h4{max-height:44px;overflow: hidden;border: none;padding: 0;margin: 0;font-size: 12px;line-height: 24px;}
.main-timeline2 .lh-20{line-height: 20px;margin-top:7px;overflow: hidden;}
.main-timeline2 .lh-20 .iconfont{float: left;margin-right: 5px;}
.main-timeline2 .lh-20 span{padding:0 5px;float: left;color: rgba(0,0,0,.7);font-size: 12px;}
.main-timeline2 .circle .mod-list{padding: 10px 15px;height: auto;}
.main-timeline2 .circle .mod-list li{line-height: 30px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;height: 30px;overflow: hidden;}
.main-timeline2 .mod-list li a{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;float: left;}
.main-timeline2 .mod-list li .mod-list-title2{width:680px;font-weight:600;}
.main-timeline2 .mod-list li .site{width:80px;padding-left: 10px;color: #474747;float: left;}
.main-timeline2 .site span{padding-right: 10px;}
.demo2 .title{display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:3;}
.main-timeline2 .cont{display: table;padding:0 5px;position: relative;width:auto;margin: 0;}
.main-timeline2 .year{display: block;margin:0 0 0 27px;border-radius: 7px;font-size:14px;color: #fff;width:112px;height:38px;background-color: #2a67b3;border-radius:8px;margin-top:2px;line-height: 38px;text-align: center;}
.main-timeline2 .icon{width:16px;height:16px;border-radius: 50%;background: #fff;border:3px solid #2a67b3;position: absolute;top:38px;left: -8px;display: inline-block;}
.main-timeline2 .item{overflow: hidden;position: relative;width: 100%;}
.demo2 .item:nth-child(2n) .timeline-content:before{display: none;}
.demo2 .item:nth-child(2n) .main-title{margin-top: 10px;}
.main-timeline2 .bd_n:before{display: none;}
.item_title .main-title{margin-top: 0;}
.mar-t-17{margin-top: 17px;}
.fxbg.pt_0{padding-top: 0;}
</style>
